import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../app/store";
import { getMonthDays, getTimeDayCalendar, getCalendarLastDate } from "../api/wordpress";

export interface CalendarState {
  status: "idle" | "loading" | "failed";
  timeStatus: "idle" | "loading" | "failed";
  days: any[];
  day: string | null;
  times: any;
  time: any;
  lastDate?: string | null;
}

const initialState: CalendarState = {
  status: "idle",
  timeStatus: "idle",
  days: [],
  day: null,
  times: {},
  time: "",
};

export const getMonthDaysAsync = createAsyncThunk(
  "calendar/getMonthDays",
  async (date: string) => {
    try {
      const response = await getMonthDays(date);
      return response.data;
    } catch (error) {
      console.error({ error });
      throw error;
    }
  }
);

export const getTimeDayCalendarAsync = createAsyncThunk(
  "calendar/getTimeDayCalendar",
  async (params: any) => {
    try {
      const response = await getTimeDayCalendar(params);
      return response.data;
    } catch (error) {
      console.error({ error });
      throw error;
    }
  }
);

export const getCalendarLastDateAsync = createAsyncThunk(
  "calendar/getCalendarLastDate",
  async (durationId: number | string) => {
    try {
      const response = await getCalendarLastDate(durationId);
      return response.data;
    } catch (error) {
      console.error({ error });
      throw error;
    }
  }
);

export const slice = createSlice({
  name: "calendar",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Actions
    setDay: (state, action) => {
      state.day = action.payload;
    },
    setTime: (state, action) => {
      state.time = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getMonthDaysAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMonthDaysAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.days = action.payload;
      })
      .addCase(getMonthDaysAsync.rejected, (state) => {
        state.status = "failed";
      });

    builder
      .addCase(getTimeDayCalendarAsync.pending, (state) => {
        state.timeStatus = "loading";
        state.time = null;
      })
      .addCase(getTimeDayCalendarAsync.fulfilled, (state, action) => {
        state.timeStatus = "idle";
        const times = action.payload?.data || {};
        state.times = times;
      })
      .addCase(getTimeDayCalendarAsync.rejected, (state) => {
        state.timeStatus = "failed";
      });

    builder
      .addCase(getCalendarLastDateAsync.pending, (state) => {
        state.lastDate = null;
      })
      .addCase(getCalendarLastDateAsync.fulfilled, (state, action) => {
        const lastDate = action.payload?.data?.day || null;
        state.lastDate = lastDate;
      })
      .addCase(getCalendarLastDateAsync.rejected, (state) => {
        state.lastDate = null;
      });
  },
});

export const { setDay, setTime } = slice.actions;

export const selectStatus = (state: RootState) => state.calendar.status;
export const selectDays = (state: RootState) => state.calendar.days;
export const selectDay = (state: RootState) => state.calendar.day;
export const selectTimes = (state: RootState) => state.calendar.times;
export const selectTime = (state: RootState) => state.calendar.time;
export const selectTimeStatus = (state: RootState) => state.calendar.timeStatus;
export const selectLastDate = (state: RootState) => state.calendar.lastDate;

export default slice.reducer;
