import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface CustomerInfoState {
  firstName: any;
  lastName: any;
  email: any;
  phone: any;
  firstNameValid: boolean,
  lastNameValid: boolean,
  emailValid: boolean,
  phoneValid: boolean,
}

const initialState: CustomerInfoState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  firstNameValid: true,
  lastNameValid: true,
  emailValid: true,
  phoneValid: true,
};

export const slice = createSlice({
  name: "customerInfo",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Actions
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setFirstNameValid: (state, action) => {
      state.firstNameValid = action.payload;
    },
    setLastNameValid: (state, action) => {
      state.lastNameValid = action.payload;
    },
    setEmailValid: (state, action) => {
      state.emailValid = action.payload;
    },
    setPhoneValid: (state, action) => {
      state.phoneValid = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
});

export const { setFirstName, setLastName, setEmail, setPhone, setFirstNameValid, setLastNameValid, setEmailValid, setPhoneValid } = slice.actions;

export const selectFirstName = (state: RootState) => state.customerInfo.firstName;
export const selectLastName = (state: RootState) => state.customerInfo.lastName;
export const selectEmail = (state: RootState) => state.customerInfo.email;
export const selectPhone = (state: RootState) => state.customerInfo.phone;
export const selectFirstNameValid = (state: RootState) => state.customerInfo.firstNameValid;
export const selectLastNameValid = (state: RootState) => state.customerInfo.lastNameValid;
export const selectEmailValid = (state: RootState) => state.customerInfo.emailValid;
export const selectPhoneValid = (state: RootState) => state.customerInfo.phoneValid;

export default slice.reducer;
