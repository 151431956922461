import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectPhone,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  selectEmailValid,
  selectFirstNameValid,
  selectLastNameValid,
  selectPhoneValid,
  setFirstNameValid,
  setLastNameValid,
  setEmailValid,
  setPhoneValid,
} from "../../reducers/customerInfoSlice";
import { selectFormValid } from "../../reducers/validationStepperSlice";
import Validator from "../../validations/validator";
import { styled } from "@mui/material/styles";

export default function FormInfo() {
  const dispatch = useAppDispatch();

  const firstNameValid = useAppSelector(selectFirstNameValid);
  const lastNameValid = useAppSelector(selectLastNameValid);
  const emailValid = useAppSelector(selectEmailValid);
  const phoneValid = useAppSelector(selectPhoneValid);

  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const email = useAppSelector(selectEmail);
  const phone = useAppSelector(selectPhone);
  const formValid = useAppSelector(selectFormValid);
  // const errors = useAppSelector(selectErrors);

  const rules = {
    firstName: !firstName && "This field is required",
    lastName: !lastName && "This field is required",
    email: !email ? 'The field is required' : !Validator.isEmail(email) && "This email is invalid",
    phone: phone
      ? !Validator.isPhoneNumber(phone) && "The phone is invalid"
      : "",
  };

  const handleChange = (event: any, name: string) => {
    const value = event?.target?.value;
    let isValid = true;

    switch (name) {
      case "firstName":
        isValid = !!value;
        dispatch(setFirstNameValid(isValid));
        dispatch(setFirstName(value));
        break;

      case "lastName":
        isValid = !!value;
        dispatch(setLastNameValid(isValid));
        dispatch(setLastName(value));
        break;

      case "email":
        isValid = !!value && Validator.isEmail(value);
        dispatch(setEmailValid(isValid));
        dispatch(setEmail(value));
        break;

      case "phone":
        isValid = !!value ? Validator.isPhoneNumber(value) : true;
        dispatch(setPhoneValid(isValid));
        dispatch(setPhone(value));
        break;

      default:
        break;
    }
  };

  return (
    <div className="flamingo-info-form">
      <p>
        Once you have filled in your information, your departure time will be
        reserved for 10 minutes.
      </p>
      <Box>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 2 }}
          className="flamingo-info-wraper"
        >
          <Grid
            item
            xs={12}
            sm={6}
            className="info-wraper-box"
            // order={{ xs: 0, sm: 0 }}
          >
            <TextField
              fullWidth={true}
              placeholder="First name"
              id="first_name"
              value={firstName}
              onChange={(e: any) => handleChange(e, "firstName")}
              required
              error={(!firstNameValid || !formValid) && !!rules.firstName}
              helperText={(!firstNameValid || !formValid) && rules.firstName}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className="info-wraper-box"
            // order={{ xs: 1, sm: 2 }}
          >
            <TextField
              fullWidth={true}
              placeholder="Last name"
              id="last_name"
              value={lastName}
              onChange={(e: any) => handleChange(e, "lastName")}
              required
              error={(!lastNameValid || !formValid) && !!rules.lastName}
              helperText={(!lastNameValid || !formValid) && rules.lastName}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className="info-wraper-box"
            // order={{ xs: 2, sm: 1 }}
          >
            <TextField
              fullWidth={true}
              placeholder="E-mail"
              type="email"
              id="email"
              value={email}
              onChange={(e: any) => handleChange(e, "email")}
              required
              error={(!emailValid || !formValid) && !!rules.email}
              helperText={(!emailValid || !formValid) && rules.email}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className="info-wraper-box"
            // order={{ xs: 3, sm: 3 }}
          >
            <TextField
              fullWidth={true}
              placeholder="Phone number"
              type="tel"
              id="phone_number"
              value={phone}
              onChange={(e: any) => handleChange(e, "phone")}
              error={(!phoneValid || !formValid) && !!rules.phone}
              helperText={(!phoneValid || !formValid) && rules.phone}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
