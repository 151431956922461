import React, { useEffect } from "react";

import { useAppSelector, useAppDispatch } from "../../app/hooks";

import {
  setServiceId,
  getServiceByIdAsync,
  setCount,
  selectServices,
  selectStatus,
  selectServicetId,
  selectDurations,
  selectDuration,
  selectDurationStatus,
  selectCount,
  selectMaxCount,
  getServicesAsync,
  setDuration,
} from "../../reducers/servicesSlice";

import SelectService from "./Dropdown";
import Counter from "./Counter";
import Durations from "./Durations";
import Backdrop from "../commons/Backdrop";
import {
  setDay,
  setTime,
  selectDay,
  getTimeDayCalendarAsync,
  getCalendarLastDateAsync,
} from "../../reducers/calendarSlice";
import { selectReservation } from "../../reducers/checkoutSlice";

interface Props {
  [key: string]: any;
}

export default function ServiceSection(props: Props) {
  const services: any[] = useAppSelector(selectServices);
  const status: string = useAppSelector(selectStatus);
  const durations: any[] = useAppSelector(selectDurations);
  const durationStatus: string = useAppSelector(selectDurationStatus);
  const service: number | string | null = useAppSelector(selectServicetId);
  const duration: number | string = useAppSelector(selectDuration);
  const count: number = useAppSelector(selectCount);
  const max: number = useAppSelector(selectMaxCount);
  const day: string | null = useAppSelector(selectDay);
  const reservation: any = useAppSelector(selectReservation);
  const loading = status === "loading";

  const durationTime = durations?.find((s: any) => s.id == duration)?.value;

  const dispatch: any = useAppDispatch();

  useEffect(() => {
    dispatch(getServicesAsync());
  }, [dispatch]);

  const handleOnChangeService = (id: number | string) => {
    dispatch(setServiceId(id));
    dispatch(setDuration(""));
    dispatch(setDay(null));
    dispatch(setTime(""));
    if (id) {
      dispatch(getServiceByIdAsync(id));
    }
  };

  const handleOnChangeCount = (amount: number) => {
    if (!service) return;
    
    let newAmoun = count + amount;
    if (newAmoun < 1) {
      newAmoun = 1;
    } else if (newAmoun > max) {
      newAmoun = max;
    }
    dispatch(setCount(newAmoun));

    if (day) {
      dispatch(
        getTimeDayCalendarAsync({
          productId: service,
          date: day,
          reservationId: reservation?.id,
          count: newAmoun,
          duration: durationTime,
        })
      );
    }
  };

  const handleOnChangeDuration = (durationId: number | string) => {
    dispatch(setDuration(durationId));
    if (day) {
      const durationValue = durations?.find((s: any) => s.id == durationId)?.value;
      dispatch(
        getTimeDayCalendarAsync({
          productId: service,
          date: day,
          reservationId: reservation?.id,
          count: count,
          duration: durationValue,
        })
      );
    }
    dispatch(getCalendarLastDateAsync(durationId));
  };

  return (
    <React.Fragment>
      <div>
        <div className="box-products">
          <SelectService
            options={services}
            value={service}
            onChange={handleOnChangeService}
            status={status}
          />
          <Counter
            value={count}
            min={1}
            max={max}
            onChange={handleOnChangeCount}
          />
        </div>
        <div>
          <Durations
            options={durations}
            value={duration}
            onChange={handleOnChangeDuration}
            status={durationStatus}
          />
        </div>
      </div>
      <Backdrop open={loading} />
    </React.Fragment>
  );
}
