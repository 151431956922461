import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectDuration,
  selectServices,
  selectServicetId,
  selectCount,
  selectDurations,
} from "../../reducers/servicesSlice";
import { selectDay, selectTime } from "../../reducers/calendarSlice";
import dayjs from "dayjs";
import {
  selectAceptNotifications,
  selectAceptTermsConditions,
  setAceptNotifications,
  setAceptTermsConditions,
} from "../../reducers/checkoutSlice";
import WebCheckout from "../checkout/WebCheckout";
import PriceDetails from "../prices/PriceDetails";
import { defaultCallback } from "../../utils/defaults";

export default function MobileCheckout(props: any) {
  const dispatch = useAppDispatch();

  const { stepperNum, onClickPay = defaultCallback } = props;
  const services: any[] = useAppSelector(selectServices);
  const service: number | string | null = useAppSelector(selectServicetId);
  const durations: any[] = useAppSelector(selectDurations);
  const serviceId: number | string = useAppSelector(selectDuration);
  const count: number = useAppSelector(selectCount);
  const day: string | null = useAppSelector(selectDay);
  const time: string | null = useAppSelector(selectTime);
  const aceptTermsConditions: boolean = useAppSelector(
    selectAceptTermsConditions
  );
  const aceptNotifications: boolean = useAppSelector(selectAceptNotifications);

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const value: any = event.target.checked;

    switch (name) {
      case "aceptTermsConditions":
        dispatch(setAceptTermsConditions(value));
        break;

      case "aceptNotifications":
        dispatch(setAceptNotifications(value));
        break;

      default:
        break;
    }
  };

  const serviceName = services.find((s: any) => s.id == service)?.name;
  const price = durations.find((d: any) => d.id == serviceId)?.price;
  const duration = durations.find((d: any) => d.id == serviceId)?.value;
  const timeValue = time ? dayjs(`1970-01-01T${time}:00`) : null;
  const timeRange = timeValue
    ? timeValue.format("HH:mm") +
      " - " +
      timeValue.add(duration as number, "hour").format("HH:mm")
    : "";

  return (
    <div>
      <Accordion className="flg-mobile-your-booking">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="flamingo-mobile-checkout"
        >
          <Typography>Your Booking</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flg-mobile-box__bg">
            <p>
              Your booking:{" "}
              <span className="flg-mobile-service-name">{serviceName}</span>
            </p>
            <strong>
              {day != null ? dayjs(day).format("MMMM DD, YYYY") : ""}
            </strong>
            <p>{timeRange}</p>
            {/* <div className="mobile-product-checkout">
              <p>
                {count}{" "}
                <span className="flg-mobile-service-name">{serviceName}</span>{" "}
                {duration} hour{parseInt(duration as string) > 1 ? "s" : ""}
              </p>
              <p>${price}</p>
            </div>
            <div className="flg-all-prince">
              <p>Total cost</p>
              <p>${price}</p>
            </div> */}
            <PriceDetails isMobile={true} />
          </div>
          <div className="flg-terms-checkout">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={aceptTermsConditions}
                    onChange={(e: any) =>
                      handleOnChange(e, "aceptTermsConditions")
                    }
                  />
                }
                label={
                  <span>
                    <a href="https://flamingo.misterfront.com/terms-and-conditions/" target="_blank">Accept terms and conditions</a>
                  </span>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={aceptNotifications}
                    onChange={(e: any) =>
                      handleOnChange(e, "aceptNotifications")
                    }
                  />
                }
                label="I would like to receive news and offers from FLAMINGO WATER SPORT & BOAT RENTAL"
              />
            </FormGroup>
          </div>
          {/* <Button className="fmb-btn-next" disabled={disabledCheckout}>
            <div className="flg-cnt-btn__container">
              <span className="flg-cnt-btn__txt">Go to Checkout</span>
              <span className="flg-cnt-btn__container--svg">
                <Bag />
              </span>
            </div>
          </Button> */}
          <WebCheckout action={"/wp-json/mf-booking-calendar-wpdevart/v1/booking-services"} stepperNum={stepperNum} onClickPay={onClickPay} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
