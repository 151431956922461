import React from "react";
import { defaultCallback } from "../../utils/defaults";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface Props {
  options: any[];
  value: any;
  status?: string;
  onChange?: Function;
}

export default function SelectService(props: Props) {
  const {
    options = [],
    value = '',
    onChange = defaultCallback,
  } = props;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} className="flg-choise-product">
      <Select
        value={value}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        className="flg-select-product"
      >
        <MenuItem value="">
          <em>Choose your service</em>
        </MenuItem>
        {options.map((option: any, index: number) => {
          return (
            <MenuItem key={`product-${index}`} value={option.id}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
