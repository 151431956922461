import * as React from "react";
import { SvgIcon } from "@mui/material";

export default function Calendar() {
    return (
        <SvgIcon fontSize="medium" viewBox="0 0 26 26">
        <path
            style={{fill: '#44206d',}}
            d="M5.79,2.55c0-.44,0-.86,0-1.28A1.38,1.38,0,0,1,7.23,0,1.38,1.38,0,0,1,8.66,1.28c0,.42,0,.84,0,1.3h8.67c0-.41,0-.81,0-1.2A1.39,1.39,0,0,1,18.79,0,1.37,1.37,0,0,1,20.2,1.36c0,.38,0,.76,0,1.18.46.07.87.11,1.28.2a5.27,5.27,0,0,1,4.5,5c0,4.38,0,8.76,0,13.14,0,2.81-2.5,5.07-5.7,5.11-4.86.05-9.73.06-14.6,0A5.41,5.41,0,0,1,0,20.7Q0,14.37,0,8.05C0,5,2.13,2.91,5.48,2.61A1.84,1.84,0,0,0,5.79,2.55ZM2.93,13a5,5,0,0,0-.05.5c0,2.35,0,4.7,0,7.06,0,1.74,1.19,2.78,3.14,2.78H20c2,0,3.13-1.05,3.14-2.84,0-2.32,0-4.63,0-6.94,0-.18,0-.35,0-.56Zm0-2.66H23a23,23,0,0,0,0-3.11,2.6,2.6,0,0,0-2.79-2c0,.32,0,.64,0,1,0,1-.51,1.57-1.42,1.57a1.37,1.37,0,0,1-1.45-1.55c0-.34,0-.67,0-1H8.66c0,.45,0,.87,0,1.29A1.35,1.35,0,0,1,7.23,7.78,1.32,1.32,0,0,1,5.8,6.52c0-.42,0-.84,0-1.26a2.58,2.58,0,0,0-2.8,2A23.92,23.92,0,0,0,3,10.35Z" />
        <path
            style={{fill: '#44206d',}}
            d="M8.66,5.23c0,.45,0,.87,0,1.29A1.35,1.35,0,0,1,7.23,7.78,1.32,1.32,0,0,1,5.8,6.52c0-.42,0-.84,0-1.26V2.55c0-.44,0-.86,0-1.28A1.38,1.38,0,0,1,7.23,0,1.38,1.38,0,0,1,8.66,1.28c0,.42,0,.84,0,1.3Zm11.54,0c0,.32,0,.64,0,1,0,1-.51,1.57-1.42,1.57a1.37,1.37,0,0,1-1.45-1.55c0-.34,0-.67,0-1V2.58c0-.41,0-.81,0-1.2A1.39,1.39,0,0,1,18.79,0,1.37,1.37,0,0,1,20.2,1.36c0,.38,0,.76,0,1.18Z" />
        <rect
            style={{fill: '#44206d',}} x="5.99" y="15.22" width="4.5" height="4.04" />
        </SvgIcon>

    );
}