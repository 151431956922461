import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import env from "./EnvVar";


class AxiosService {
    private static instance: AxiosInstance;

    private constructor() {

    }

    public static getInstance(options = {}): AxiosInstance {
        if (!AxiosService.instance) {
            const baseURL = (env.apiUrl || '') + (env.flamingoApiNs || '');

            AxiosService.instance = axios.create({
                ...{
                    baseURL,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                },
                ...options
            });

            // Agregar un interceptor de solicitudes
            AxiosService.instance.interceptors.request.use(
                (config: InternalAxiosRequestConfig) => {
                   // config.headers['Authorization'] = 'Bearer token';
                    return config;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );

            // Agregar un interceptor de respuestas
            AxiosService.instance.interceptors.response.use(
                (response: AxiosResponse) => {
                    return response;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        }

        return AxiosService.instance;
    }
}

export default AxiosService;
