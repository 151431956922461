import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import env from "./EnvVar";


class WooService {
    private static instance: AxiosInstance;

    private constructor() {

    }

    public static getInstance(options = {}): AxiosInstance {
        if (!WooService.instance) {
            const baseURL = `${env.apiUrl}${env.wcApiNs}`;
            const consumerKey = env.wcConsumerKey;
            const consumerSecret = env.wcConsumerSecret;           

            WooService.instance = axios.create({
                ...{
                    baseURL,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + Buffer.from(consumerKey + ":" + consumerSecret).toString('base64')
                    },
                    withCredentials: true
                },
                ...options
            });

            // Agregar un interceptor de solicitudes
            WooService.instance.interceptors.request.use(
                (config: InternalAxiosRequestConfig) => {
                   // config.headers['Authorization'] = 'Bearer token';
                    return config;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );

            // Agregar un interceptor de respuestas
            WooService.instance.interceptors.response.use(
                (response: AxiosResponse) => {
                    return response;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        }

        return WooService.instance;
    }
}

export default WooService;
