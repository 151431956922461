import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { SvgIcon } from "@mui/material";
import ArrowRight from "./svg/ArrowRight";
import { defaultCallback } from "../../utils/defaults";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions(props: any) {
  const { items = [], onChangeStep = defaultCallback } = props;

  const [expanded, setExpanded] = React.useState<string>("panel1");

  const changePanel = (
    panel: string,
    item: any,
    newExpanded: boolean,
    manual: boolean = false
  ) => {
    if (!manual && !newExpanded) {
      return;
    }
    const newPanelNumber = parseInt(panel.replace("panel", ""));
    const currentPanelNumber = parseInt(expanded.replace("panel", ""));

    let canExpand = false;

    const defaultCallback = () => true;
    const {
      prev = defaultCallback,
      next = defaultCallback,
    }: { [key: string]: any } = item?.conditions || {};

    if (newPanelNumber > currentPanelNumber) {
      // Next
      canExpand = next();
    } else {
      // Prev
      canExpand = prev();
    }

    if (canExpand === true) {
      setExpanded(panel);

      const activeStep = newPanelNumber - 1;
      onChangeStep(activeStep);
    }
  };

  const handleChange =
    (panel: string, item: any, manual: boolean = false) =>
    (event: React.SyntheticEvent, newExpanded: boolean) => {
      changePanel(panel, item, newExpanded, manual);
    };

  return (
    <React.Fragment>
      {items &&
        items.length > 0 &&
        items.map((item: any, index: number) => {
          const panelNumber = `panel${index + 1}`;
          const ChildComponent = item.content;
          const showNextButton = item.showNextButton || true;
          const textNextButton = item.textNextButton || "Next";
          const svgIcon = item.svgIcon || <ArrowRight />;
          const nextPanelNumber =
            index + 2 <= items.length ? `panel${index + 2}` : "";
            
          return (
            <Accordion
              key={panelNumber}
              id={`accordion-${panelNumber}`}
              expanded={expanded === panelNumber}
              onChange={handleChange(panelNumber, item)}
            >
              <AccordionSummary
                aria-controls={`${panelNumber}d-content`}
                id={`${panelNumber}d-header`}
                className="fmg-ac__title"
              >
                <Typography>
                  <span>{item.icon}</span>
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="fmg-ac__detail">
                {ChildComponent}
                {nextPanelNumber !== "" && showNextButton && (
                  <Button
                    variant="contained"
                    className="fmb-btn-next"
                    onClick={() =>
                      changePanel(nextPanelNumber, item, true, true)
                    }
                  >
                    <div className="flg-cnt-btn__container">
                      <span className="flg-cnt-btn__txt">{textNextButton}</span>
                      <span className="flg-cnt-btn__container--svg">
                        {svgIcon}
                      </span>
                    </div>
                  </Button>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </React.Fragment>
  );
}
