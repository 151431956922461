import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import ToggleButtons from "../commons/ToggleButtons";
import { PickerSelectionState } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectDay,
  setDay,
  getTimeDayCalendarAsync,
  selectTimes,
  selectLastDate
} from "../../reducers/calendarSlice";
import { selectServicetId, selectCount, selectDurations, selectDuration } from "../../reducers/servicesSlice";
import { selectReservation } from "../../reducers/checkoutSlice";

function getRandomNumber(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formattedMonth = month < 10 ? `0${month}` : month;

  const formattedDate = `${year}-${formattedMonth}-${day}`;

  return formattedDate;
};

/**
 * Mimic fetch with abort controller https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 * ⚠️ No IE11 support
 */
function fakeFetch(date: Dayjs, { signal }: { signal: AbortSignal }) {
  return new Promise<{ daysToHighlight: number[] }>((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth();
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      );

      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException("aborted", "AbortError"));
    };
  });
}

const currentDate = getCurrentDate();
const initialValue = dayjs(currentDate);

function ServerDay(
  props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) > 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? "" : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default function CalendarSection() {
  const dispatch = useAppDispatch();

  const productId: number | string = useAppSelector(selectServicetId);
  const times: any = useAppSelector(selectTimes);
  const day: string | null = useAppSelector(selectDay);
  const reservation: any = useAppSelector(selectReservation);
  const count: any = useAppSelector(selectCount);
  const durations: any[] = useAppSelector(selectDurations);
  const durationId: number | string = useAppSelector(selectDuration);
  const lastDate: string | null | undefined = useAppSelector(selectLastDate);
  
  const duration = durations?.find((s: any) => s.id == durationId)?.value;
  const dayValue = day != null ? dayjs(day) : day;

  const requestAbortController = React.useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);

  const fetchHighlightedDays = (date: Dayjs) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays(initialValue);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date: Dayjs) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  const handleDayChange = (
    value: Dayjs | null,
    selectionState?: PickerSelectionState | undefined
  ): void => {
    const date = value?.format("YYYY-MM-DD");
    const params = {
      productId,
      date,
      reservationId:
        typeof reservation?.id == "string"
          ? parseInt(reservation.id)
          : reservation?.id,
      count,
      duration
    };

    dispatch(setDay(date));
    dispatch(getTimeDayCalendarAsync(params));
  };

  return (
    <div className="flg-container2">
      <div className="flamingo-calendar">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            // defaultValue={day}
            value={dayValue}
            loading={isLoading}
            // onMonthChange={handleMonthChange}
            onChange={handleDayChange}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                highlightedDays,
              } as any,
            }}
            minDate={dayjs(currentDate)}
            maxDate={lastDate ? dayjs(lastDate) : undefined}
          />
        </LocalizationProvider>
        <div className="info-under-calendar">
          <p>Remember to arrive at least 15 mins before the sail-out time.</p>
          <p>Please note:</p>
          <ul>
            <li>Alcohol-free location.</li>
            <li>
              We will refuse to take guests who are intoxicated upon arrival.
            </li>
          </ul>
        </div>
      </div>
      <div className="flamingo-reserve">
        {!!day && <ToggleButtons times={times} />}
        {!day && (
          <div className="flg-message-select-date">
            <p>
              <span>Select a date to get the available timeslots</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
