import * as React from "react";
import { SvgIcon } from "@mui/material";

export default function CheckStrong() {
    return (
        <SvgIcon fontSize="medium" viewBox="0 0 10.74 8.32">
            <path style={{fill: '#fff'}}
                d="M6.65,6.3,4.84,8.11c-.32.31-.61.27-1-.09L.45,4.62.21,4.38a.59.59,0,0,1,0-.91L1.51,2.18a.6.6,0,0,1,1,0c.69.68-.56-.56.13.12L4.4,4.05C5.13,4.82,5.89,5.56,6.65,6.3Z" />
            <path style={{fill: '#fff'}}
                d="M4.94,3.55C6.06,2.43,7.18,1.3,8.3.19a.57.57,0,0,1,.85,0l1.4,1.4a.56.56,0,0,1,0,.84L7.15,5.8s0-.09,0-.11C6.39,5,5.7,4.23,4.94,3.55Z" />
            <path style={{fill: '#fff'}}
                d="M4.94,3.55C5.7,4.23,6.39,5,7.11,5.69c0,0,0,.07,0,.11a3.38,3.38,0,0,1-.5.5C5.89,5.56,5.13,4.82,4.4,4.05Z" />
        </SvgIcon>

    );
}