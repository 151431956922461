import React from "react";
import BackdropComponent from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { defaultCallback } from "../../utils/defaults";

interface Props {
  open: boolean;
  handleClose?: Function;
}

export default function Backdrop(props: Props, handleClose = defaultCallback) {
  const { open } = props;

  return (
    <BackdropComponent
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </BackdropComponent>
  );
}
