import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckIcon from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import CheckStrong from "../commons/svg/CheckStrong";
import { useAppSelector } from "../../app/hooks";
import { selectActiveStep } from "../../reducers/stepperSlice";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 13,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
  },
  [`&.${stepConnectorClasses.active}, .flg-step-connector__active`]: {
    [`& .${stepConnectorClasses.line}, .flg-step-connector__active-line`]: {
      backgroundColor: "#ff2b80",
    },
  },
  [`&.${stepConnectorClasses.completed}, .flg-step-connector__completed`]: {
    [`& .${stepConnectorClasses.line}, .flg-step-connector__line`]: {
      backgroundColor: "#ff2b80",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#ff2b80",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#ff2b80",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <CheckStrong />,
    2: <CheckStrong />,
    3: <CheckStrong />,
    4: <CheckStrong />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className + " flg-step-label__icon"}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export default function CustomizedSteppers(props: any) {
  const { length = 2 } = props;
  const steps = Array.from(Array(length));
  const activeStep: number = useAppSelector(selectActiveStep);

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        className="flg-sptepper-container__x1"
      >
        {steps.map((label, index) => (
          <Step
            key={label + "--hxddwq--" + index}
            className="flg-sptepper-step__x1"
          >
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              className="flg-sptepper-label__x1"
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
