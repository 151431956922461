import * as React from "react";
import { SvgIcon } from "@mui/material";

export default function Calendar() {
    return (
        <SvgIcon fontSize="medium" viewBox="0 0 26 26">
            <path style={{fill: '#44206d',}}
                d="M0,13A13,13,0,1,1,13,26,13,13,0,0,1,0,13ZM13,23.15A10.15,10.15,0,1,0,2.85,13,10.18,10.18,0,0,0,13,23.15Z" />
            <path style={{fill: '#44206d',}}
                d="M11.92,10.51c0-1,0-2,0-2.94a1.06,1.06,0,0,1,1.14-1.09,1.08,1.08,0,0,1,1,1.14c0,.14,0,.27,0,.41,0,1.5,0,3,0,4.51a1.19,1.19,0,0,0,.32.78c1.1,1.15,2.24,2.26,3.35,3.39a1.11,1.11,0,0,1,.3,1.33,1.06,1.06,0,0,1-1.7.35q-2.13-2.07-4.19-4.21a1.58,1.58,0,0,1-.29-1c0-.89,0-1.79,0-2.69Z" />
        </SvgIcon>

    );
}