import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// import styles from "./ToggleButtons.module.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectTime,
  setTime as setAlignment,
  selectTimeStatus,
  selectDay
} from "../../reducers/calendarSlice";
import { selectServicetId } from "../../reducers/servicesSlice";
import Spinner from "./Spinner";

export default function ToggleButtons(props: any) {
  const dispatch = useAppDispatch();
  const { times } = props;  

  const alignment = useAppSelector(selectTime);
  const timeStatus = useAppSelector(selectTimeStatus);
  const day = useAppSelector(selectDay);
  const productId = useAppSelector(selectServicetId);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    dispatch(setAlignment(newAlignment));
  };

  return (
    <React.Fragment>
      {timeStatus == "loading" && (
        <div className="schedules-loading">
          <Spinner />
        </div>
      )}
      {timeStatus == "failed" && (
        <div className="schedules-failed">
          <p>An error has occurred, please try again later</p>
        </div>
      )}
      {timeStatus == "idle" && (!times || Object.keys(times).length == 0) && (
        <div className="no-schedules-available">
          <p>No schedules available</p>
        </div>
      )}
      {timeStatus == "idle" && !!times && Object.keys(times).length > 0 && (
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          className="toggle-button schedules-toggle-button"
        >
          {!!times &&
            Object.keys(times).map((time: string) => {
              let available = times[time]?.available;
              let bookable = times[time]?.bookable;
              
              const disabled = bookable == false;

              if (typeof available === "string") {
                available = parseInt(available);
              }

              return (
                <ToggleButton
                  className="flg-toggle-button-hour"
                  value={time}
                  disabled={disabled}
                  key={`${productId}-${day}-${time}`}
                >
                  <div className="box-cst-btn">
                    <div className="btn-cst-radio">{time}</div>
                    {bookable && available > 0 && available <= 2 && (
                        <div className="tg-box-left">{available} LEFT</div>
                      )}
                  </div>
                </ToggleButton>
              );
            })}
        </ToggleButtonGroup>
      )}
    </React.Fragment>
  );
}
