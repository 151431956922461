import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Button from "@mui/material/Button";
// import Bag from "../commons/svg/Bag";
// import Input from "@mui/material/Input";
import { selectDuration, selectCount } from "../../reducers/servicesSlice";
import { selectDay, selectTime } from "../../reducers/calendarSlice";
import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectPhone,
} from "../../reducers/customerInfoSlice";
import {
  selectAceptTermsConditions,
  bookingServiceAsync,
} from "../../reducers/checkoutSlice";
import { selectActiveStep } from "../../reducers/stepperSlice";
import { defaultCallback } from "../../utils/defaults";

const CheckoutButton = (props: any) => {
  const {
    // onSubmit = defaultCallback,
    stepperNum,
    onClickPay = defaultCallback,
  } = props;

  const count: number = useAppSelector(selectCount);
  const serviceId: number | string = useAppSelector(selectDuration);
  const day: string | null = useAppSelector(selectDay);
  const time: string | null = useAppSelector(selectTime);
  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const email = useAppSelector(selectEmail);
  // const phone = useAppSelector(selectPhone);
  const aceptTermsConditions = useAppSelector(selectAceptTermsConditions);
  const activeStep: number = useAppSelector(selectActiveStep);

  const enableCheckout: boolean =
    !!serviceId &&
    !!count &&
    !!day &&
    !!time &&
    !!firstName &&
    !!lastName &&
    !!email &&
    !!aceptTermsConditions &&
    activeStep === stepperNum - 1;

  // const handleSubmit = (event: React.FormEvent<EventTarget>) => {
  //   event.preventDefault();

  //   const body = {
  //     serviceId,
  //     count,
  //     day,
  //     time,
  //     firstName,
  //     lastName,
  //     email,
  //     phone,
  //     termsConditions: aceptTermsConditions,
  //   };

  //   onSubmit(body);
  // };

  return (
    // <form onSubmit={handleSubmit} method="POST">
    <Button
      type="submit"
      className={`fmb-btn-next ${!enableCheckout && "fmb-btn-next-disabled"}`}
      disabled={!enableCheckout}
      onClick={onClickPay}
    >
      <div className="flg-cnt-btn__container">
        <span className="flg-cnt-btn__txt">Go to Checkout</span>
        <span className="flg-cnt-btn__container--svg">{/* <Bag /> */}</span>
      </div>
    </Button>
    // </form>
  );
};

const Message = ({ message }: any) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function WebCheckout(props: any) {
  const { stepperNum, onClickPay = defaultCallback } = props;

  const [message, setMessage] = useState("");

  const dispatch = useAppDispatch();

  const handleSubmit = (body: any) => {
    dispatch(bookingServiceAsync(body));
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage(
        "Order placed! You will receive an email confirmation. This page will refresh in a few seconds"
      );
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready. This page will refresh in a few seconds"
      );
    }
  }, []);

  if (message) {
    setTimeout(function () {
      window.location.href = window.location.pathname;
    }, 10000);
  }

  return message ? (
    <Message message={message} />
  ) : (
    <CheckoutButton
      stepperNum={stepperNum}
      // onSubmit={handleSubmit}
      onClickPay={onClickPay}
    />
  );
}
