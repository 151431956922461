import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../app/store";
import { getServices, getServiceById } from "../api/woocommerce";

export interface ServiceState {
  status: "idle" | "loading" | "failed"; // status getting service list
  services: { [key: string]: any }[]; // service list
  serviceId: number | string; // service selected
  durationStatus: "idle" | "loading" | "failed"; // status getting durations list
  durations: { [key: string]: any }[]; // duration list
  count: number; // count selected
  duration: number | string; // duration selected
  maxCount: number;
}

const initialState: ServiceState = {
  status: "idle",
  services: [],
  serviceId: "",
  durationStatus: "idle",
  durations: [],
  count: 1,
  duration: "",
  maxCount: 20,
};

export const getServicesAsync = createAsyncThunk(
  "services/getServices",
  async () => {
    try {
      const response = await getServices();
      return response.data;
    } catch (error) {
      console.error({ error });
      throw error;
    }
  }
);

export const getServiceByIdAsync = createAsyncThunk(
  "services/getServiceById",
  async (id: number | string) => {
    try {
      const response = await getServiceById(id);
      return response.data;
    } catch (error) {
      console.error({ error });
      throw error;
    }
  }
);

export const slice = createSlice({
  name: "services",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Actions
    setServiceId: (state, action) => {
      const serviceId = action.payload;
      let maxCount: any = 100;
      state.serviceId = serviceId;
      const services = [...state.services];
      const service = services.find((s: any) => s.id == serviceId);
      const metaData = service?.meta_data || [];
      maxCount = metaData.find((md: any) => md?.key == '_booking_qty_available')?.value;
      if (!maxCount && service && service?.meta_data) {
        maxCount = parseInt(service.stock_quantity);
      } else {
        maxCount = parseInt(maxCount);
      }      

      state.maxCount = maxCount;
      if (state.count > maxCount) state.count = maxCount || 1;
      if (!serviceId) {
        state.count = 1;
        state.durations = [];
        state.duration = '';
      };
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Services
      .addCase(getServicesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getServicesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.services = action.payload;
      })
      .addCase(getServicesAsync.rejected, (state) => {
        state.status = "failed";
      });

    builder
      // Variations
      .addCase(getServiceByIdAsync.pending, (state) => {
        state.durationStatus = "loading";
      })
      .addCase(getServiceByIdAsync.fulfilled, (state, action) => {
        state.durationStatus = "idle";
        const variations = action.payload;

        let durations: any[] = [];

        for (let index = 0; index < variations.length; index++) {
          try {
            const variation = variations[index];
            const { attributes = [], price, meta_data = [] } = variation;
            const attributeHour: any = attributes.length ? attributes[0] : null;
            let { option: optionValue } = attributeHour;
            if (typeof optionValue === "string") {
              optionValue = parseInt(optionValue);
            }

            const deposit: any = {};

            if (meta_data) {
              for (let j = 0; j < meta_data.length; j++) {
                const metaData: any = meta_data[j];
                if (
                  ![
                    "_enable_deposit",
                    "_deposits_type",
                    "_deposits_value",
                  ].includes(metaData.key)
                ) {
                  continue;
                }

                deposit[metaData.key] = metaData.value;
              }
            }

            durations.push({
              id: variation.id,
              value: optionValue,
              price,
              deposit,
            });
          } catch (error) {
            console.error(error);
            continue;
          }
        }

        if (durations.length)
          durations = durations.sort((a: any, b: any) => a.value - b.value);

        state.durations = durations;
      })
      .addCase(getServiceByIdAsync.rejected, (state) => {
        state.durationStatus = "failed";
      });
  },
});

export const { setServiceId, setCount, setDuration } = slice.actions;

export const selectServices = (state: RootState) => state.service.services;
export const selectStatus = (state: RootState) => state.service.status;
export const selectServicetId = (state: RootState) => state.service.serviceId;
export const selectDurations = (state: RootState) => state.service.durations;
export const selectDuration = (state: RootState) => state.service.duration;
export const selectDurationStatus = (state: RootState) =>
  state.service.durationStatus;
export const selectCount = (state: RootState) => state.service.count;
export const selectMaxCount = (state: RootState) => state.service.maxCount;

export default slice.reducer;
