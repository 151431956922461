import wooService from "../woocommerce";

export function getServices() {
  const instance = wooService.getInstance(); // Instancia de axios
  return instance.get(`/products`);
}

export function getServiceById(id: number | string) {
  const instance = wooService.getInstance(); // Instancia de axios
  return instance.get(`/products/${id}/variations`);
}
