import * as React from "react";
import {
  selectCount,
  selectDuration,
  selectDurations,
  selectServices,
  selectServicetId,
} from "../../reducers/servicesSlice";
import { useAppSelector } from "../../app/hooks";
import { selectCurrency } from "../../reducers/optionSlice";

const convertToMoney = (num: number | string, currency: string) => {
  if (typeof num == 'string') num = parseFloat(num);

  const browserLang = window.navigator.language || window.navigator.languages[0];
  const numFormatted = num ? num.toLocaleString(browserLang, {
    style: "currency",
    currency,
  }) : '';

  return numFormatted;
};

function PricesDesktop(props: any) {
  const {
    serviceName,
    count,
    duration,
    price,
    totalPrice,
    initialPayment,
    initialPaymentType = "",
    duePayment,
    currency
  } = props.params;
  return (
    <React.Fragment>
      <div className="detail-product-checkout">
        <p>
          <span className="flg-detail-service-name">{serviceName}</span>{" "}
          {duration} hour{parseInt(duration as string) > 1 ? "s" : ""}
        </p>
        <p>{convertToMoney(price, currency)}</p>
      </div>
      <div className="detail-product-checkout">
        <p>Qty</p>
        <p>{count}</p>
      </div>
      {/* <div className="flg-all-prince">
        <p>Sub total</p>
        <p>{totalPrice}</p>
      </div> */}
      <div className="flg-all-prince">
        <p>Total cost</p>
        <p>{convertToMoney(totalPrice, currency)}</p>
      </div>
      {!!initialPayment && (
        <React.Fragment>
          <div className="detail-product-checkout">
            <p>Initial payment {initialPaymentType}</p>
            <p>{convertToMoney(initialPayment, currency)}</p>
          </div>
          <div className="detail-product-checkout">
            <p>Due payment</p>
            <p>{convertToMoney(duePayment, currency)}</p>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function PricesMobile(props: any) {
  const {
    serviceName,
    count,
    duration,
    price,
    totalPrice,
    initialPayment,
    initialPaymentType = "",
    duePayment,
    currency
  } = props.params;
  return (
    <React.Fragment>
      <div className="mobile-product-checkout">
        <p>
          <span className="flg-mobile-service-name">{serviceName}</span>{" "}
          {duration} hour{parseInt(duration as string) > 1 ? "s" : ""}
        </p>
        <p>{convertToMoney(price, currency)}</p>
      </div>
      <div className="mobile-product-checkout">
        <p>Qty: {count}</p>
      </div>
      <div className="flg-all-prince">
        <p>Total cost</p>
        <p>{convertToMoney(totalPrice, currency)}</p>
      </div>
      {!!initialPayment && (
        <React.Fragment>
          <div className="detail-product-checkout">
            <p>Initial payment {initialPaymentType}</p>
            <p>{convertToMoney(initialPayment, currency)}</p>
          </div>
          <div className="detail-product-checkout">
            <p>Due payment</p>
            <p>{convertToMoney(duePayment, currency)}</p>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default function PriceDetails(props: any) {
  const { isMobile = false } = props;
  const durations: any[] = useAppSelector(selectDurations);
  const durationId: number | string = useAppSelector(selectDuration);
  const count: number = useAppSelector(selectCount);
  const services: any[] = useAppSelector(selectServices);
  const service: number | string | null = useAppSelector(selectServicetId);
  const currency: string = useAppSelector(selectCurrency);

  const getDurationAttribute = (attr: string, defaultValue: any = null) => {
    const found = durations.find((s: any) => s.id == durationId);
    if (!found) return defaultValue;
    return found[attr] || defaultValue;
  };

  const serviceName = services.find((s: any) => s.id == service)?.name;
  const price = getDurationAttribute("price");
  const duration = getDurationAttribute("value");
  const deposit = getDurationAttribute("deposit", {});

  let initialPayment: number = 0;
  let initialPaymentFormatted: string = "";
  let initialPaymentType = "";
  if (deposit?._enable_deposit == "yes") {
    let {
      _deposits_type: depositsType,
      _deposits_value: depositsValue,
    }: { [key: string]: any } = deposit;
    depositsValue = parseFloat(depositsValue);
    if (depositsType == "percent") {
      initialPayment = (price * depositsValue) / 100;
      initialPaymentType = `(${depositsValue}%)`;
    } else {
      initialPayment += depositsValue;
    }

    initialPayment *= count;

    initialPaymentFormatted = initialPayment.toFixed(2);
  }

  const totalPrice = price * count;
  const duePayment = totalPrice - initialPayment;

  return (
    <React.Fragment>
      {!isMobile && (
        <PricesDesktop
          params={{
            serviceName,
            count,
            duration,
            price,
            totalPrice,
            duePayment,
            initialPayment: initialPaymentFormatted,
            initialPaymentType,
            currency
          }}
        />
      )}
      {isMobile && (
        <PricesMobile
          params={{
            serviceName,
            count,
            duration,
            price,
            totalPrice,
            duePayment,
            initialPayment,
            initialPaymentFormatted,
            initialPaymentType,
            currency
          }}
        />
      )}
    </React.Fragment>
  );
}
