import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import * as stripeJs from "@stripe/stripe-js";
import { Button } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import { selectDuration, selectCount } from "../../reducers/servicesSlice";
import { selectDay, selectTime } from "../../reducers/calendarSlice";
import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectPhone,
} from "../../reducers/customerInfoSlice";
import {
  selectAceptNotifications,
  selectAceptTermsConditions,
  selectReservation,
} from "../../reducers/checkoutSlice";
import Spinner from "../commons/Spinner";
import { bookingPayment } from "../../api/wordpress";
import {
  selectSucceeded,
  setSucceeded,
  selectProcessing,
  setProcessing,
} from "../../reducers/checkoutSlice";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();

  //   const [succeeded, setSucceeded] = useState<boolean>(false);
  //   const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(true);

  const count: number = useAppSelector(selectCount);
  const serviceId: number | string = useAppSelector(selectDuration);
  const day: string | null = useAppSelector(selectDay);
  const time: string | null = useAppSelector(selectTime);
  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const email = useAppSelector(selectEmail);
  const phone = useAppSelector(selectPhone);
  const aceptTermsConditions = useAppSelector(selectAceptTermsConditions);
  const aceptNotifications = useAppSelector(selectAceptNotifications);
  const reservation: any = useAppSelector(selectReservation);
  const succeeded: boolean = useAppSelector(selectSucceeded);
  const processing: boolean = useAppSelector(selectProcessing);

  const paymentElementOptions: stripeJs.StripeCardElementOptions = {
    disableLink: true,
    hidePostalCode: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event: any) => {
    setDisabled(!!(event.empty || event.error));
    setError(event.error ? event.error.message : "");
  };

  const handleError = (error: any) => {
    dispatch(setProcessing(false));
    setError(error.message);
  };

  const handleSubmit = async (ev: React.FormEvent<EventTarget>) => {
    ev.preventDefault();

    try {
      if (processing || !stripe || !elements) {
        return;
      }

      dispatch(setProcessing(true));

      const body = {
        reservationId: reservation?.id,
        serviceId,
        count,
        day,
        time,
        firstName,
        lastName,
        email,
        phone,
        termsConditions: aceptTermsConditions,
        notifications: aceptNotifications
      };

      const res = await bookingPayment(body);
      if (!res) {
        handleError({
          message: "An error has occurred, please try again later",
        });
        return;
      }

      const resData = res.data;
      const { clientSecret } = resData?.data || {};

      if (!clientSecret) {
        handleError({
          message: "An error has occurred, please try again later",
        });
        return;
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        console.error("Could not get item from credit card.");
        handleError({
          message: "An error has occurred, please try again later",
        });
        return;
      }

      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${firstName} ${lastName}`,
            email: email,
            phone: phone || "",
          },
        },
      });

      if (payload.error) {
        handleError(payload.error);
      } else {
        setError(null);
        dispatch(setProcessing(false));
        dispatch(setSucceeded(true));
      }
    } catch (err: any) {
      console.error({ processingPaymentError: err });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {!succeeded && (
        <React.Fragment>
          <CardElement
            id="payment-element"
            options={paymentElementOptions}
            onChange={handleChange}
          />
          {!processing ? (
            <Button
              type="submit"
              disabled={disabled || !stripe || !elements}
              id="submit"
              className={`fmb-btn-next`}
            >
              <div className="flg-cnt-btn__container" id="button-text">
                <span className="flg-cnt-btn__txt">Confirm payment</span>
                <span className="flg-cnt-btn__container--svg">
                  <PaymentIcon />
                </span>
              </div>
            </Button>
          ) : (
            <div className="flg-proccessing-box">
              <div className="flg-proccessing-box-spinner">
                <Spinner />
              </div>
              <div className="flg-proccessing-box-text">
                <p>
                  <span>Proccessing...</span>
                </p>
              </div>
            </div>
          )}
        </React.Fragment>
      )}

      {!processing && error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {!!succeeded && (
        <div id="payment-message">
          <p className="result-message">
            <span>Payment succeeded</span>
          </p>
          <p className="refresh-message">
            <span>Refresh the page to booking again.</span>
          </p>
        </div>
      )}
    </form>
  );
}
