import wpService from "../service";

export function getOptions() {
  const instance = wpService.getInstance();
  return instance.get(`/booking-options`);
}

export function getCalendarLastDate(serviceId: number | string) {
  const instance = wpService.getInstance();
  return instance.get(`/calendar-last-date`, { params: { serviceId } });
}

export function getMonthDays(date: string) {
  const instance = wpService.getInstance();
  return instance.get(`/calendar/months/${date}`);
}

export function getTimeDayCalendar(params: any) {
  const instance = wpService.getInstance();
  return instance.get(`/calendar-reservation`, { params });
}

export function bookingService(body: any) {
  const instance = wpService.getInstance();
  return instance.post(`/booking`, body);
}

export function bookingPayment(body: any) {
  const instance = wpService.getInstance();
  return instance.post(`/booking/payment`, body);
}