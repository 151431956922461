import React from 'react';
import { Booking } from './features/booking/views/Booking';
import './App.css';

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  return (
    <div className="App">
      <Booking />
    </div>
  );
}

export default App;
