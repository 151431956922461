import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  loadStripe,
  StripeElementsOptions,
  Appearance,
} from "@stripe/stripe-js";
import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectPhone,
} from "../../reducers/customerInfoSlice";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { selectCurrency } from "../../reducers/optionSlice";
import {
  selectCount,
  selectDuration,
  selectDurations,
} from "../../reducers/servicesSlice";

const container = document.getElementById('root')!;
const pKey: string = container?.getAttribute("pKey") || '';

const stripePromise = loadStripe(pKey);

export default function StripeForm(props: any) {
  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const email = useAppSelector(selectEmail);
  const phone = useAppSelector(selectPhone);
  const currency: string = useAppSelector(selectCurrency);
  const count: number = useAppSelector(selectCount);
  const durations: any[] = useAppSelector(selectDurations);
  const durationId: number | string = useAppSelector(selectDuration);

  const durationitem = durations.find((s: any) => s.id == durationId);
  const price: number = durationitem?.price || 0;
  const deposit: any = durationitem?.deposit || {};

  let initialPayment: number = 0;
  let initialPaymentType = "";

  if (deposit?._enable_deposit == "yes") {
    let {
      _deposits_type: depositsType,
      _deposits_value: depositsValue,
    }: { [key: string]: any } = deposit;
    depositsValue = parseFloat(depositsValue);
    if (depositsType == "percent") {
      initialPayment = (price * depositsValue) / 100;
      initialPaymentType = `(${depositsValue}%)`;
    } else {
      initialPayment += depositsValue;
    }

    initialPayment *= count;
  }

  const totalPrice = price * count;
  const duePayment = totalPrice - initialPayment;

  const amount = initialPayment ? initialPayment : totalPrice;

  const appearance: Appearance = {
    theme: "stripe",
  };  

  const options: StripeElementsOptions = {
    mode: "payment",
    currency,
    amount: amount * 100,
    appearance,
  };

  return (
    <React.Fragment>
      <h4>Your information</h4>
      <div className="App">
        <div className="flg-stripe-info__container">
          <div className="flg-stripe_info">
            <label>First Name</label>
            <p>{firstName || ""}</p>
          </div>
          <div className="flg-stripe_info">
            <label>Last Name</label>
            <p>{lastName || ""}</p>
          </div>
          <div className="flg-stripe_info">
            <label>Email</label>
            <p>{email || ""}</p>
          </div>
          <div className="flg-stripe_info">
            <label>Phone</label>
            <p>{phone || ""}</p>
          </div>
        </div>
        <div className="flg-box-info-pay-before-button">
          <p>
            To be paid: {currency.toUpperCase()} {amount.toFixed(2)}{" "}
            {!!initialPayment && `/ Due payment: ${duePayment.toFixed(2)}`}
          </p>
        </div>
        {!!amount && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </React.Fragment>
  );
}
