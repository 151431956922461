import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import serviceReducer from '../reducers/servicesSlice';
import calendarReducer from '../reducers/calendarSlice';
import customerInfoReducer from '../reducers/customerInfoSlice';
import checkoutReducer from '../reducers/checkoutSlice';
import stepperReducer from '../reducers/stepperSlice';
import validationStepper from '../reducers/validationStepperSlice';
import optionReducer from '../reducers/optionSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    service: serviceReducer,
    calendar: calendarReducer,
    customerInfo: customerInfoReducer,
    checkout: checkoutReducer,
    stepper: stepperReducer,
    validationStepper: validationStepper,
    option: optionReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
