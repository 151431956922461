import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { bookingService, bookingPayment } from "../api/wordpress";

export interface CheckoutState {
  aceptTermsConditions: boolean;
  aceptNotifications: boolean;
  status: "sending" | "payment" | "idle" | "failed";
  clientSecret?: string;
  orderId?: number;
  reservation?: any;
  succeeded: boolean;
  error: string | null;
  processing: boolean;
  disabled: boolean;
}

const initialState: CheckoutState = {
  aceptTermsConditions: false,
  aceptNotifications: false,
  status: "idle",
  succeeded: false,
  error: null,
  processing: false,
  disabled: false
};

export const bookingServiceAsync = createAsyncThunk(
  "option/bookingService",
  async (body: any) => {
    try {
      const response = await bookingService(body);
      return response.data;
    } catch (error) {
      console.error({ error });
      throw error;
    }
  }
);

export const bookingPaymentAsync = createAsyncThunk(
  "option/bookingPayment",
  async (body: any) => {
    try {
      const response = await bookingPayment(body);
      return response.data;
    } catch (error) {
      console.error({ error });
      throw error;
    }
  }
);

export const slice = createSlice({
  name: "checkout",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Actions
    setAceptTermsConditions: (state, action) => {
      state.aceptTermsConditions = action.payload;
    },
    setAceptNotifications: (state, action) => {
      state.aceptNotifications = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setProcessing: (state, action) => {
      state.processing = action.payload;
    },
    setDisabled: (state, action) => {
      state.disabled = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(bookingServiceAsync.pending, (state) => {
        state.status = "sending";
      })
      .addCase(bookingServiceAsync.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { reservation } = data || {};
        state.reservation = reservation;

        if (Object.keys(reservation).length > 0) {
          if (window.innerWidth >= 900) {
            setTimeout(() => document.getElementById("flg-coutdown-scroll")?.scrollIntoView({ behavior: "smooth" }), 500);
          }
        }

        state.status = "idle";
      })
      .addCase(bookingServiceAsync.rejected, (state) => {
        state.status = "failed";
      })

      .addCase(bookingPaymentAsync.pending, (state) => {
        state.status = "payment";
      })
      .addCase(bookingPaymentAsync.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { clientSecret, orderId, reservation } = data || {};
        state.clientSecret = clientSecret;
        state.orderId = orderId;
        state.reservation = reservation;
        state.status = "idle";
      })
      .addCase(bookingPaymentAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  setAceptTermsConditions,
  setAceptNotifications,
  setSucceeded,
  setError,
  setProcessing,
  setDisabled,
} = slice.actions;

export const selectAceptTermsConditions = (state: RootState) =>
  state.checkout.aceptTermsConditions;
export const selectAceptNotifications = (state: RootState) =>
  state.checkout.aceptNotifications;
export const selectClientSecret = (state: RootState) =>
  state.checkout.clientSecret;
export const selectReservation = (state: RootState) =>
  state.checkout.reservation;
export const selectSucceeded = (state: RootState) => state.checkout.succeeded;
export const selectError = (state: RootState) => state.checkout.error;
export const selectProcessing = (state: RootState) => state.checkout.processing;
export const selectDisabled = (state: RootState) => state.checkout.disabled;

export default slice.reducer;
