import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { getOptions } from "../api/wordpress";

export interface OptionState {
  status: "loading" | "idle" | "failed";
  termAndConditionsLink: string;
  currency: string;
  gallery: any
}

const initialState: OptionState = {
  status: "idle",
  termAndConditionsLink: "",
  currency: "usd",
  gallery: null
};

export const getOptionsAsync = createAsyncThunk(
  "option/getOptions",
  async () => {
    try {
      const response = await getOptions();
      return response.data;
    } catch (error) {
      console.error({ error });
      throw error;
    }
  }
);

export const slice = createSlice({
  name: "option",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Actions
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getOptionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOptionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        const res = action.payload || {};
        const { termAndConditionsLink, currency = "usd", gallery = null } = res?.data?.options || {};

        state.termAndConditionsLink = termAndConditionsLink;
        state.currency = currency;

        const items: any[] = [];

        if (gallery && gallery?.files && Array.isArray(gallery?.files)) {
          gallery.files.forEach(({
            url,
            mime_type: mimeType,
            type,
            sizes
          }: any) => {

            if (!["image", "video"].includes(type)) return;

            const item: any = {
              url,
              type,
              mimeType,
            };

            if (type === "image" && sizes) {
              item.sizes = sizes;
            }

            items.push(item);
          });
        }        

        state.gallery = {
          ...gallery,
          items
        };
      })
      .addCase(getOptionsAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// export const {  } = slice.actions;

export const selectTermAndConditionsLink = (state: RootState) => state.option.termAndConditionsLink;
export const selectCurrency = (state: RootState) => state.option.currency;
export const selectGallery = (state: RootState) => state.option.gallery;

export default slice.reducer;
