import * as React from "react";
import { SvgIcon } from "@mui/material";

export default function Info() {
    return (
        <SvgIcon fontSize="medium" viewBox="0 0 26 26">
            <path style={{
                fill: '#44206d',
            }}
                d="M0,13A13,13,0,1,1,5.87,23.86c-1.72.31-2.94.48-4.81.8.5-2.42.59-2.84,1-4.72A12.89,12.89,0,0,1,0,13ZM13,23.15A10.15,10.15,0,1,0,2.85,13,10.18,10.18,0,0,0,13,23.15Z" />
            <path style={{
                fill: '#44206d',
            }} d="M23.15,13A10.17,10.17,0,0,0,13,2.85L13.05,0A13,13,0,0,1,26,13Z" />
            <path style={{
                fill: '#44206d',
            }}
                d="M12.35,10.82h1.57a.81.81,0,0,1,.83,1l-.93,4.75c0,.18-.07.36-.11.55a.64.64,0,0,0,.82.72c.25-.08.49-.21.73-.31a.91.91,0,0,1,.14-.07c.21-.05.35.08.29.29a1.07,1.07,0,0,1-.2.38A2.54,2.54,0,0,1,14,19a3.7,3.7,0,0,1-2.3-.19,1.38,1.38,0,0,1-.89-1.24,3.13,3.13,0,0,1,.07-.8c.26-1.45.52-2.9.79-4.36a.55.55,0,0,0-.52-.7l-.47,0a.46.46,0,0,1-.39-.47.4.4,0,0,1,.43-.38Z" />
            <path style={{
                fill: '#44206d',
            }} d="M13.37,6.9A1.57,1.57,0,1,1,11.8,8.47,1.58,1.58,0,0,1,13.37,6.9Z" />
        </SvgIcon>

    );
}