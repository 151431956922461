import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface StepperState {
  activeStep: any;
}

const initialState: StepperState = {
  activeStep: 0,
};

export const slice = createSlice({
  name: "stepper",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Actions
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
});

export const { setActiveStep } = slice.actions;

export const selectActiveStep = (state: RootState) => state.stepper.activeStep;

export default slice.reducer;
