import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useAppSelector } from "../../app/hooks";
import { selectReservation, selectSucceeded } from "../../reducers/checkoutSlice";


const convertSecondsToMinutesSeconds = (seconds: number) => {
  var minutes = Math.floor(seconds / 60);
  var remainingSeconds = seconds % 60;
  return minutes + ":" + (remainingSeconds < 10 ? "0" : "") + remainingSeconds;
};

const renderTime = ({ remainingTime }: any) => {
  if (remainingTime === 0) {
    return <div className="timer">00:00</div>;
  }

  const time = convertSecondsToMinutesSeconds(remainingTime);
  return (
    <div className="timer">
      {/* <div className="timer--text">Remaining</div> */}
      <div className="timer--value">{time}</div>
      {/* <div className="timer--text">seconds</div> */}
    </div>
  );
};

export default function Countdown(props: any ) {
  const {id} = props
  const reservation = useAppSelector(selectReservation);
  const succeeded = useAppSelector(selectSucceeded);

  return (
    <React.Fragment>
      {!!reservation && !succeeded && 
        <div className="coutdown-wrapper" id={id}>
          <div className="coutdown--info">
            <p>
              <b>Your departure time is reserved</b>
            </p>
            <p>
              After 10 minutes, we cannot guarantee that your departure time is
              still available.
            </p>
          </div>
          <div className="coutdown--timer-circle">
            <div className="timer-wrapper">
              <CountdownCircleTimer
                key={reservation?.expiration_date}
                isPlaying
                duration={10*60}
                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                colorsTime={[10, 6, 3, 0]}
                onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                size={55}
                strokeWidth={4}
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
          </div>
        </div>
      }
      {!reservation && <div></div>}
    </React.Fragment>
  );
}
