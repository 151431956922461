import isEmailValid from 'validator/lib/isEmail';

export const isPhoneNumber = (value: string) => {
    const re = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    return re.test(value);
}

export const isEmail = (value: string) => {
    return isEmailValid(value);
}

export default {
    isPhoneNumber,
    isEmail
}