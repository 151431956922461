import React from "react";
import { defaultCallback } from "../../utils/defaults";
import { Counter as CounterInput } from "../../features/counter/Counter";

interface Props {
  value: number;
  min?: number;
  max?: number;
  onChange: Function;
}

export default function Counter(props: Props) {
  const { value, min, max, onChange = defaultCallback } = props;

  const handleChange = (event: number) => {
    onChange(event);
  };

  return (
    <div className="flg-box-counter">
      <span className="counter-label">Quantity:</span>
      <CounterInput count={value} min={min} max={max} onChange={handleChange} />
    </div>
  );
}
