import React, { useState } from "react";
import TextField from "@mui/material/TextField";

import styles from "./Counter.module.css";
import { defaultCallback } from "../../utils/defaults";

export function Counter(props: any) {
  const { count, onChange = defaultCallback } = props;
  return (
    <div>
      <div className={styles.row + ' flg-box-count'}>
        <button
          className={styles.button}
          aria-label="Decrement value"
          onClick={() => onChange(-1)}
        >
          -
        </button>
        {/* <span className={styles.value}>{count}</span> */}
        <input id="outlined-controlled" value={count} readOnly />
        <button
          className={styles.button}
          aria-label="Increment value"
          onClick={() => onChange(1)}
        >
          +
        </button>
      </div>
    </div>
  );
}
