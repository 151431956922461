import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface ValidationStepperState {
  // errors: {
  //   [key: string]: {
  //     error: boolean;
  //     errorMessage: string;
  //   };
  // }[];
  formValid: boolean;
}

const initialState: ValidationStepperState = {
  // errors: [
  //   {
  //     firstName: {
  //       error: true,
  //       errorMessage: "hola",
  //     },
  //   },
  // ],
  formValid: true,
};

export const slice = createSlice({
  name: "validationStepper",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Actions
    setFormValid: (state, action) => {
      state.formValid = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
});

export const { setFormValid } = slice.actions;

export const selectFormValid = (state: RootState) =>
  state.validationStepper.formValid;

export default slice.reducer;
