import React from "react";
import { defaultCallback } from "../../utils/defaults";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { TableHead } from "@mui/material";

interface Props {
  options: any[];
  value: number | string;
  status: string;
  onChange: Function;
}

export default function Durations(props: Props) {
  const { options = [], value = "", onChange = defaultCallback } = props;

  const handleChangeDuration = (
    event: React.MouseEvent<HTMLElement>,
    duration: string | number
  ) => {
    onChange(duration);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      // onChange={handleChangeDuration}
      aria-label="Platform"
      className="flg-touch-container-hours"
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell variant="head" colSpan={options.length}>
                Duration
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {options?.map((option: any, index: number) => {
                return (
                  <TableCell
                    key={`hourOption-${index}-${option.id}-${option.value}`}
                    align="center"
                  >
                    <ToggleButton
                      key={`hourOption-${index}-${option.id}-${option.value}`}
                      value={option.id}
                      onChange={handleChangeDuration}
                      className={`flg-touch-button-hours ${
                        option.id == value ? "actived" : ""
                      }`}
                    >
                      {option.value} Hour{option.value > 1 ? "s" : ""}
                    </ToggleButton>
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {options?.map((option: any, index: number) => {
                return (
                  <TableCell
                    key={`hourOption-${index}-${option.id}-${option.price}`}
                    align="center"
                  >
                    <span
                      className={`flg-touch-button-hours ${
                        option.id == value ? "actived" : ""
                      }`}
                    >
                      &#36;{option.price} + taxes included
                    </span>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ToggleButtonGroup>
  );
}
