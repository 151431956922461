import * as React from "react";
import { SvgIcon } from "@mui/material";

export default function BagPurple() {
    return (
        <SvgIcon fontSize="medium" viewBox="0 0 21 26">
            <path style={{fill: '#44206d'}}
                d="M15.78,5.82c2.31.13,3.74,1.17,4,2.85.2,1.48.34,3,.51,4.45.23,2.06.42,4.13.69,6.19C21.44,23,18.62,26,14.62,26c-2.75,0-5.51,0-8.26,0C2.49,26-.33,23.12,0,19.58S.8,12.52,1.22,9c.23-2,1.56-3.07,3.7-3.15l.28,0A5,5,0,0,1,7.37,1,5.38,5.38,0,0,1,10.66,0a5.33,5.33,0,0,1,4,1.86A5.09,5.09,0,0,1,15.78,5.82ZM7.18,7.68v.68c0,.87,0,1.74,0,2.6a.91.91,0,0,1-1,.92.94.94,0,0,1-1-.87c0-.21,0-.41,0-.62V7.69c-1.24-.07-1.88.39-2,1.44-.2,1.55-.36,3.11-.53,4.67-.23,2-.48,4-.66,6a4,4,0,0,0,4.22,4.4c2.86,0,5.72,0,8.57,0A4.09,4.09,0,0,0,19,20.87a7.23,7.23,0,0,0-.07-2c-.34-3.27-.71-6.53-1.08-9.8-.12-1-.84-1.45-2-1.34,0,.12,0,.25,0,.37,0,1,0,1.93,0,2.89a1,1,0,0,1-2,0c0-.29,0-.57,0-.85V7.68Zm6.6-1.85A3.25,3.25,0,0,0,12.2,2.25a3.52,3.52,0,0,0-3.65.15A3.13,3.13,0,0,0,7.27,5.83Z" />
            <path style={{fill: '#44206d'}}
                d="M13.23,16.47l1.77,0a4.74,4.74,0,0,1-1.85,4.45,4.33,4.33,0,0,1-2.79.87A4.42,4.42,0,0,1,7,20.11a4.9,4.9,0,0,1-1-3.63h1.7a3.06,3.06,0,0,0,1.34,3.28,2.8,2.8,0,0,0,3.1-.14A3,3,0,0,0,13.23,16.47Z" />
        </SvgIcon>

    );
}