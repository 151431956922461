import * as React from "react";
import { SvgIcon } from "@mui/material";

export default function ArrowRight() {
    return (
        <SvgIcon fontSize="small">             
            <path style={{
                stroke:"none",
                fillRule:"nonzero",
                fill:"rgb(30.196078%,76.862745%,92.54902%)",
                fillOpacity:"1"
            }} d="M 9.839844 6.503906 C 10.789062 7.445312 11.738281 8.375 12.671875 9.324219 C 13.160156 9.816406 13.101562 10.324219 12.53125 10.84375 L 4.21875 19.144531 C 4.101562 19.265625 3.980469 19.394531 3.851562 19.515625 C 3.675781 19.726562 3.414062 19.851562 3.140625 19.851562 C 2.863281 19.851562 2.605469 19.726562 2.429688 19.515625 C 1.75 18.84375 1.078125 18.175781 0.429688 17.515625 C 0.183594 17.339844 0.0351562 17.058594 0.0351562 16.753906 C 0.0351562 16.453125 0.183594 16.167969 0.429688 15.996094 L 3.628906 12.796875 L 6.328125 10.003906 C 7.53125 8.863281 8.699219 7.699219 9.839844 6.503906 Z M 9.839844 6.503906 "/>
            <path style={{
                stroke:"none",
                fillRule:"nonzero",
                fill:"rgb(94.901961%,94.901961%,94.901961%)",
                fillOpacity:"1"
            }} d="M 5.550781 9.144531 C 3.800781 7.425781 2 5.683594 0.300781 3.933594 C 0.109375 3.769531 0.00390625 3.53125 0.00390625 3.28125 C 0.00390625 3.027344 0.109375 2.789062 0.300781 2.625 C 1.019531 1.886719 1.75 1.15625 2.488281 0.433594 C 2.65625 0.246094 2.894531 0.140625 3.148438 0.144531 C 3.398438 0.144531 3.636719 0.253906 3.800781 0.445312 C 5.570312 2.195312 7.308594 3.964844 9.070312 5.726562 C 9.011719 5.726562 8.929688 5.726562 8.890625 5.785156 C 7.769531 6.90625 6.609375 7.984375 5.550781 9.144531 Z M 5.550781 9.144531 "/>
            <path style={{
                stroke:"none",
                fillRule:"nonzero",
                fill:"rgb(16.078431%,80%,78.039216%)",
                fillOpacity:"1"
            }} d="M 5.550781 9.144531 C 6.609375 7.984375 7.769531 6.90625 8.890625 5.785156 C 8.890625 5.785156 9.011719 5.785156 9.070312 5.726562 C 9.355469 5.957031 9.613281 6.21875 9.839844 6.503906 C 8.699219 7.699219 7.53125 8.863281 6.328125 10.003906 Z M 5.550781 9.144531 "/>
        </SvgIcon>

    );
}